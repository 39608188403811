<template>
  <Content :search="false">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <h2>Jauns rēķins</h2>
      </div>
    </template>

    <template v-slot:content>
      <template v-if="customerData && form.customer && form.customer.notes">
        <PopupAlertRelative :text="form.customer.notes" className="-mt-5" />
      </template>

      <form
        v-if="inputData"
        class="space-y-6 w-full bg-white dark:bg-gray-750 p-4 rounded-lg"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="col-span-1 md:col-span-2">
            <h2 class="text-xl font-bold dark:text-gray-300">Klients</h2>
          </div>

          <div class="col-span-1">
            <Autocomplete
              placeholder="Izvēlēties klientu"
              v-model="form.customer"
              url="/api/fetch_customers"
              :params="{ q: q.customer, select_id: q.customer_id }"
              :errors="errors.customer"
              :clearOnClick="false"
            />
          </div>

          <template v-if="form.customer">
            <div class="flex items-end">
              <div class="flex flex-wrap w-full">
                <ItemText title="Reģ. nr." :text="form.customer.reg_nr" />
                <ItemText
                  title="PVN. nr."
                  :text="
                    form.customer.vat_nr ? form.customer.vat_nr : 'Nav norādīts'
                  "
                />
                <ItemText title="Adrese" :text="form.customer.address" />
              </div>
            </div>
          </template>

          <template v-if="customerData && form.customer">
            <div class="col-span-1">
              <Autocomplete
                placeholder="Saņemšanas vieta"
                v-model="form.customerBranch"
                :errors="errors.customerBranch"
                :items="customerData.branches"
                :showItems="true"
              />
            </div>
          </template>
        </div>

        <template v-if="customerData && form.customer">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="col-span-1 md:col-span-2">
              <h2 class="text-xl font-bold dark:text-gray-300">Rēķina dati</h2>
            </div>

            <div class="col-span-1">
              <Input
                type="date"
                placeholder="Rēķina datums"
                v-model="form.invoiceDate"
                :errors="errors.invoiceDate"
                @change="changeDueDate"
              />
            </div>

            <div class="col-span-1">
              <Input
                type="date"
                placeholder="Perioda datums"
                v-model="form.periodLastDate"
                :errors="errors.periodLastDate"
                @change="getInvoiceableCustomerData"
              />
            </div>

            <template v-if="customerData">
              <div class="col-span-1">
                <Select
                  v-model="form.ownerBranch"
                  :errors="errors.ownerBranch"
                  :items="mainCustomer.branches"
                  placeholder="Izsniegšanas vieta"
                />
              </div>
            </template>

            <template v-if="customerData.payment_details">
              <div class="col-span-1">
                <Input
                  type="date"
                  placeholder="Apmaksas termiņš"
                  v-model="customerData.payment_details.due_date"
                />
              </div>
            </template>

            <div class="col-span-1">
              <Radio
                label="Valoda:"
                name="lang"
                v-model="form.lang"
                :items="[
                  { name: 'LV', value: 'lv' },
                  { name: 'ENG', value: 'en' },
                ]"
                :errors="errors.lang"
              />
            </div>

            <template
              v-if="
                form.prepared_electronically ||
                form.prepared_electronically === 0
              "
            >
              <div class="col-span-1">
                <Radio
                  label="Sagatavots elektroniski:"
                  name="prepared_electronically"
                  v-model="form.prepared_electronically"
                  :items="[
                    { name: 'Jā', value: 1 },
                    { name: 'Nē', value: 0 },
                  ]"
                  :errors="errors.prepared_electronically"
                />
              </div>
            </template>

            <div class="col-span-1">
              <Radio
                label="Rādīt PN aktus:"
                name="display_acts"
                v-model="form.display_acts"
                :items="[
                  { name: 'Jā', value: 1 },
                  { name: 'Nē', value: 0 },
                ]"
                :errors="errors.display_acts"
              />
            </div>

            <div class="col-span-1">
              <Radio
                label="Atsauce uz 0% PVN:"
                name="reverse_vat"
                v-model="form.reverse_vat"
                :items="[
                  { name: 'Jā', value: 1 },
                  { name: 'Nē', value: 0 },
                ]"
                :errors="errors.reverse_vat"
              />
            </div>

            <div class="col-span-1">
              <Input
                type="number"
                placeholder="Avanss EUR"
                v-model="form.advance"
                :errors="errors.advance"
              />
            </div>

            <div class="col-span-1 md:col-span-2" style="height: 100px">
              <Textarea
                placeholder="Piezīmes"
                v-model="form.notes"
                :errors="errors.notes"
              />
            </div>
          </div>
        </template>

        <template v-if="customerData && form.customer">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-6">
            <div class="col-span-1 md:col-span-2 flex items-center gap-5">
              <Checkbox text="Noma" v-model="form.selectRentItems" />
              <Checkbox text="Pasūtījumi" v-model="form.selectOrderItems" />
              <Checkbox text="Serviss" v-model="form.selectServiceItems" />
            </div>

            <div class="col-span-1 md:col-span-2">
              <div
                class="
                  grid grid-cols-1
                  md:grid-cols-2
                  gap-6
                  divide-y divide-gray-300
                  dark:divide-gray-700
                "
              >
                <template v-if="form.selectRentItems">
                  <template v-if="customerData.rent_invoiceable_items">
                    <div class="col-span-1 md:col-span-2">
                      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-6">
                        <div class="col-span-1 md:col-span-2">
                          <h2 class="text-xl font-bold dark:text-gray-300">
                            Noma
                          </h2>
                        </div>

                        <template
                          v-if="
                            customerData.rent_invoiceable_items
                              .closed_invoiced_items.length > 0
                          "
                        >
                          <template
                            v-for="item in customerData.rent_invoiceable_items
                              .closed_invoiced_items"
                            :key="item.id"
                          >
                            <div
                              class="
                                col-span-1
                                md:col-span-2
                                flex
                                items-center
                                gap-5
                              "
                            >
                              <Checkbox
                                text="Atzīmēt noslēgtos"
                                v-model="selectAllClosedRentInvoiceItems"
                              />
                            </div>

                            <div
                              class="
                                col-span-1
                                md:col-span-2
                                flex
                                items-center
                                gap-5
                              "
                            >
                              <ClosedRentItem
                                :item="item"
                                :toggleItemIsSelected="getSelectedItems"
                                :recalculateTotals="recalculateTotals"
                              />
                            </div>
                          </template>
                        </template>

                        <template
                          v-if="
                            customerData.rent_invoiceable_items
                              .unclosed_invoiced_items.length > 0
                          "
                        >
                          <div
                            class="
                              col-span-1
                              md:col-span-2
                              flex
                              items-center
                              gap-5
                            "
                          >
                            <Checkbox
                              text="Atzīmēt neslēgtos"
                              v-model="selectAllUnclosedRentInvoiceItems"
                            />
                          </div>

                          <template
                            v-for="item in customerData.rent_invoiceable_items
                              .unclosed_invoiced_items"
                            :key="item.id"
                          >
                            <div
                              class="
                                col-span-1
                                md:col-span-2
                                flex
                                items-center
                                gap-5
                              "
                            >
                              <UnclosedRentItem
                                :item="item"
                                :toggleItemIsSelected="getSelectedItems"
                                :recalculateTotals="recalculateTotals"
                              />
                            </div>
                          </template>
                        </template>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div class="col-span-1 md:col-span-2">
                      <p
                        class="
                          font-bold
                          text-lg text-center
                          p-5
                          dark:text-gray-400
                        "
                      >
                        Uz {{ form.periodLastDate }} visi rēķini par nomu ir
                        izrakstīti
                      </p>
                    </div>
                  </template>
                </template>

                <template v-if="form.selectOrderItems">
                  <template v-if="customerData.invoiceable_order_items">
                    <div class="col-span-1 md:col-span-2">
                      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-6">
                        <div class="col-span-1 md:col-span-2">
                          <h2 class="text-xl font-bold dark:text-gray-300">
                            Pasūtījumi
                          </h2>
                        </div>

                        <template
                          v-if="customerData.invoiceable_order_items.length > 0"
                        >
                          <div
                            class="
                              col-span-1
                              md:col-span-2
                              flex
                              items-center
                              gap-5
                            "
                          >
                            <Checkbox
                              text="Atzīmēt visus"
                              v-model="selectAllOrderInvoiceItems"
                            />
                          </div>

                          <template
                            v-for="item in customerData.invoiceable_order_items"
                            :key="item.id"
                          >
                            <div
                              class="
                                col-span-1
                                md:col-span-2
                                flex
                                items-center
                                gap-5
                              "
                            >
                              <InvoiceableOrderItem
                                :item="item"
                                :toggleItemIsSelected="getSelectedItems"
                                :recalculateTotals="recalculateTotals"
                              />
                            </div>
                          </template>
                        </template>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div class="col-span-1 md:col-span-2">
                      <p
                        class="
                          font-bold
                          text-lg text-center
                          p-5
                          dark:text-gray-400
                        "
                      >
                        Visi rēķini par pasūtījumiem ir izrakstīti
                      </p>
                    </div>
                  </template>
                </template>

                <template v-if="form.selectServiceItems">
                  <template v-if="customerData.invoiceable_service_items">
                    <div class="col-span-1 md:col-span-2">
                      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-6">
                        <div class="col-span-1 md:col-span-2">
                          <h2 class="text-xl font-bold dark:text-gray-300">
                            Serviss
                          </h2>
                        </div>

                        <template
                          v-if="
                            customerData.invoiceable_service_items.length > 0
                          "
                        >
                          <div
                            class="
                              col-span-1
                              md:col-span-2
                              flex
                              items-center
                              gap-5
                            "
                          >
                            <Checkbox
                              text="Atzīmēt visus"
                              v-model="selectAllServiceInvoiceItems"
                            />
                          </div>

                          <template
                            v-for="item in customerData.invoiceable_service_items"
                            :key="item.id"
                          >
                            <div
                              class="
                                col-span-1
                                md:col-span-2
                                flex
                                items-center
                                gap-5
                              "
                            >
                              <InvoicebleServiceItem
                                :item="item"
                                :toggleItemIsSelected="getSelectedItems"
                                :recalculateTotals="recalculateTotals"
                              />
                            </div>
                          </template>
                        </template>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div class="col-span-1 md:col-span-2">
                      <p
                        class="
                          font-bold
                          text-lg text-center
                          p-5
                          dark:text-gray-400
                        "
                      >
                        Visi rēķini par servisu ir izrakstīti
                      </p>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>

          <div
            class="grid grid-cols-1 md:grid-cols-2 gap-6"
            v-observe-visibility="visibilityChanged"
          >
            <div class="col-span-1 md:col-span-2 flex flex-col justify-end">
              <div class="flex justify-end mb-2 dark:text-gray-300">
                <span class="mr-2">Summa EUR:</span>
                <span class="font-bold">{{ total.toFixed(2) }}</span>
              </div>

              <div class="flex justify-end mb-2 dark:text-gray-300">
                <span class="mr-2">PVN EUR:</span>
                <span class="font-bold">{{ totalVat.toFixed(2) }}</span>
              </div>

              <div class="flex justify-end mb-2 dark:text-gray-300">
                <span class="mr-2">Kopā apmaksai EUR:</span>
                <span class="font-bold">{{ totalWithVat.toFixed(2) }}</span>
              </div>
            </div>
          </div>

          <div
            v-show="showSnackbar"
            class="bg-main4 p-5 fixed bottom-10 right-10 rounded-lg shadow-xl"
          >
            <div class="flex justify-end">
              <span class="mr-3 text-white">Summa EUR: </span>
              <p class="font-bold text-white">{{ total.toFixed(2) }}</p>
            </div>
          </div>
        </template>

        <div class="pt-5">
          <div class="flex justify-end">
            <button
              @click="goBack"
              type="button"
              class="
                bg-white
                dark:bg-gray-750
                py-2
                px-4
                border border-gray-300
                dark:border-gray-500
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-gray-700
                dark:text-gray-300
                hover:bg-gray-50
                dark:hover:bg-gray-770
                focus:outline-none focus:ring-0
                mr-2
              "
            >
              Atcelt
            </button>

            <template
              v-if="
                rentItemsForInvoice.length > 0 ||
                orderItemsForInvoice.length > 0 ||
                serviceItemsForInvoice.length > 0
              "
            >
              <template v-if="!loading">
                <button
                  type="button"
                  class="
                    flex
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-white
                    bg-gradient-to-r
                    from-button2-from
                    to-button2-to
                    hover:from-button2-to hover:to-button2-from
                    focus:outline-none focus:border-none
                    transition
                    duration-500
                    ease-in-out
                  "
                  @click.prevent="submit"
                >
                  Saglabāt
                </button>
              </template>
              <template v-else>
                <Loading />
              </template>
            </template>
          </div>
        </div>
      </form>
    </template>
  </Content>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { debounce } from "vue-debounce";
import { mapGetters } from "vuex";
import moment from "moment"
import Calculations from "@/services/Calculations/calculations";
import Checkbox from "@/components/Components/Checkbox";
import Autocomplete from "@/components/Components/Autocomplete";
import Input from "@/components/Components/Input";
import Radio from "@/components/Components/Radio";
import Textarea from "@/components/Components/Textarea";
import ItemText from "@/components/Components/ItemText";
import Validation from "@/modules/Validation";

const ClosedRentItem = defineAsyncComponent(() =>
  import('@/components/Invoices/CreateInvoice/ClosedRentItem'))
const UnclosedRentItem = defineAsyncComponent(() =>
  import('@/components/Invoices/CreateInvoice/UnclosedRentItem'))
const InvoiceableOrderItem = defineAsyncComponent(() =>
  import('@/components/Invoices/CreateInvoice/InvoiceableOrderItem'))
const InvoicebleServiceItem = defineAsyncComponent(() =>
  import('@/components/Invoices/CreateInvoice/InvoicebleServiceItem'))
const PopupAlertRelative = defineAsyncComponent(() =>
  import('@/components/Components/PopupAlertRelative'))
const Loading = defineAsyncComponent(() =>
  import('@/components/Components/Loading'))
const Select = defineAsyncComponent(() =>
  import('@/components/Components/Select'))

export default {
  name: "CreateInvoice",
  components: {
    Autocomplete,
    ItemText,
    Input,
    Select,
    Radio,
    Textarea,
    Checkbox,
    Loading,
    ClosedRentItem,
    UnclosedRentItem,
    InvoiceableOrderItem,
    InvoicebleServiceItem,
    PopupAlertRelative,
  },
  data: () => ({
    form: {
      customer: null,
      customerBranch: null,
      invoiceDate: new Date().toISOString().substr(0, 10),
      periodLastDate: new Date().toISOString().substr(0, 10),
      ownerBranch: null,
      lang: "lv",
      prepared_electronically: null,
      reverse_vat: 0,
      display_acts: 1,
      advance: "",
      notes: "",
      selectRentItems: true,
      selectOrderItems: true,
      selectServiceItems: true,
    },
    q: {
      customer: null,
      customer_id: null,
    },
    selectAllClosedRentInvoiceItems: false,
    selectAllUnclosedRentInvoiceItems: false,
    selectAllOrderInvoiceItems: false,
    selectAllServiceInvoiceItems: false,

    showSnackbar: true,
    snackbar: true,
    timeout: -1,
    invoiceDueDate: "",

    rentItemsForInvoice: [],
    orderItemsForInvoice: [],
    serviceItemsForInvoice: [],

    total: 0,
    totalVat: 0,
    totalWithVat: 0,

    rentTotal: 0,
    rentTotalVat: 0,
    rentTotalWithVat: 0,

    orderTotal: 0,
    orderTotalVat: 0,
    orderTotalWithVat: 0,

    serviceTotal: 0,
    serviceTotalVat: 0,
    serviceTotalWithVat: 0,

    formValidation: null,
  }),
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors",
      selectedCustomer: "selectedCustomer",
      inputData: "invoiceInputData",
      customerData: "invoiceableCustomerData",
      mainCustomer: "systemMainCustomer",
      user: "user",
    }),
  },
  created() {
    this.q.customer = this.$route.query.customer
      ? this.$route.query.customer
      : null;
    this.q.customer_id = this.$route.query.customer_id
      ? this.$route.query.customer_id
      : null;

    if (this.$route.query.date) {
      this.form.periodLastDate = this.$route.query.date;
    }

    this.$store.dispatch("clearInvoiceableCustomerData");
    this.$store.dispatch("getInvoiceInputData").then((response) => {
      if (this.user) {
        this.form.ownerBranch = this.user.customer.branch;
      }
    });
  },
  watch: {
    "form.periodLastDate": function () {
      let url = this.$route.fullPath.split("&");
      url = url.filter((value) => !value.startsWith("date="));
      window.history.replaceState(
        null,
        null,
        `${url.join("&")}&date=${this.form.periodLastDate}`
      );
    },
    "form.customer": debounce(function () {
      this.allClosedInvoiceItems = false;
      this.allUnclosedInvoiceItems = false;
      this.getInvoiceableCustomerData();

      if (this.form.customer.id != this.$route.query.customer_id) {
        const date = this.$route.query.date;
        const query = {
          customer: this.form.customer.name,
          customer_id: this.form.customer.id,
        };
        if (date) {
          query.date = date;
        }
        this.$router.replace({ query: query });
      }

      this.q.customer = null;
      this.q.customer_id = null;
    }, 0),
    customerData: function () {
      this.allClosedInvoiceItems = false;
      this.allUnclosedInvoiceItems = false;
    },
    user() {
      if (this.user) {
        this.form.ownerBranch = this.user.customer.branch;
      }
    },
    "form.selectOrderItems": function () {
      this.selectAllOrderInvoiceItems = this.form.selectOrderItems;
    },
    "form.selectServiceItems": function () {
      this.selectAllServiceInvoiceItems = this.form.selectServiceItems;
    },
    "form.selectRentItems": function () {
      this.selectAllClosedRentInvoiceItems = this.form.selectRentItems;
      this.selectAllUnclosedRentInvoiceItems = this.form.selectRentItems;
    },
    selectAllClosedRentInvoiceItems: function () {
      if (this.customerData.rent_invoiceable_items) {
        if (this.customerData.rent_invoiceable_items.closed_invoiced_items) {
          this.customerData.rent_invoiceable_items.closed_invoiced_items.forEach(
            (item) => {
              item.isSelected = this.selectAllClosedRentInvoiceItems;
            }
          );
          this.getSelectedItems();
        }
      }
    },
    selectAllUnclosedRentInvoiceItems: function () {
      if (this.customerData.rent_invoiceable_items) {
        if (this.customerData.rent_invoiceable_items.unclosed_invoiced_items) {
          this.customerData.rent_invoiceable_items.unclosed_invoiced_items.forEach(
            (item) => {
              item.isSelected = this.selectAllUnclosedRentInvoiceItems;
            }
          );
          this.getSelectedItems();
        }
      }
    },
    selectAllOrderInvoiceItems: function () {
      if (this.customerData.invoiceable_order_items) {
        this.customerData.invoiceable_order_items.forEach((item) => {
          item.isSelected = this.selectAllOrderInvoiceItems;
        });
        this.getSelectedItems();
      }
    },
    selectAllServiceInvoiceItems: function () {
      if (this.customerData.invoiceable_service_items) {
        this.customerData.invoiceable_service_items.forEach((item) => {
          item.isSelected = this.selectAllServiceInvoiceItems;
        });
        this.getSelectedItems();
      }
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      this.showSnackbar = !isVisible;
    },
    changeDueDate() {
      var invoiceDate = moment(this.form.invoiceDate);
      
      this.customerData.payment_details.due_date = invoiceDate
        .add(this.customerData.payment_details.postpay_days, "days")
        .format("YYYY-MM-DD");
    },
    getInvoiceableCustomerData() {
      const customerId = this.form.customer ? this.form.customer.id : null;
      this.$store.dispatch("clearInvoiceableCustomerData");

      if (customerId) {
        this.$store
          .dispatch("getInvoiceableCustomerData", {
            customerId: customerId,
            date: this.form.periodLastDate,
          })
          .then((response) => {
            if (
              this.customerData &&
              this.customerData.payment_details.invoice_delivery_type &&
              this.customerData.payment_details.invoice_delivery_type.id === 1
            ) {
              this.form.prepared_electronically = 0;
            } else if (
              this.customerData &&
              this.customerData.payment_details.invoice_delivery_type &&
              this.customerData.payment_details.invoice_delivery_type.id === 2
            ) {
              this.form.prepared_electronically = 1;
            } else {
              this.form.prepared_electronically = 0;
            }
          });
      }
    },
    goBack() {
      if (confirm("Vai esi drošs? Visi ievadītie lauki tiks pazaudēti")) {
        this.$router.go(-1);
      }
    },
    getSelectedItems() {
      this.getSelectedRentItems();
      this.getSelectedOrderItems();
      this.getSelectedServiceItems();
      this.recalculateTotals();
    },
    getSelectedRentItems() {
      if (this.customerData.rent_invoiceable_items) {
        var closedItems =
          this.customerData.rent_invoiceable_items.closed_invoiced_items.filter(
            (item) => {
              return item.isSelected === true;
            }
          );
        var unclosedItems =
          this.customerData.rent_invoiceable_items.unclosed_invoiced_items.filter(
            (item) => {
              return item.isSelected === true;
            }
          );
        this.rentItemsForInvoice = closedItems.concat(unclosedItems);
      }
    },
    getSelectedOrderItems() {
      if (this.customerData.invoiceable_order_items) {
        this.orderItemsForInvoice =
          this.customerData.invoiceable_order_items.filter((item) => {
            return item.isSelected === true;
          });
      }
    },
    getSelectedServiceItems() {
      if (this.customerData.invoiceable_service_items) {
        this.serviceItemsForInvoice =
          this.customerData.invoiceable_service_items.filter((item) => {
            return item.isSelected === true;
          });
      }
    },
    recalculateTotals() {
      this.recalculateRentTotals();
      this.recalculateOrderTotals();
      this.recalculateServiceTotals();

      this.total = this.rentTotal + this.orderTotal + this.serviceTotal;
      this.totalVat =
        this.rentTotalVat + this.orderTotalVat + this.serviceTotalVat;
      this.totalWithVat =
        this.rentTotalWithVat +
        this.orderTotalWithVat +
        this.serviceTotalWithVat;
    },
    recalculateRentTotals() {
      this.rentTotal = Calculations.getItemSumByParamName(
        this.rentItemsForInvoice,
        "total_with_readings"
      );
      this.rentTotalVat = Calculations.getItemSumByParamName(
        this.rentItemsForInvoice,
        "vat"
      );
      this.rentTotalWithVat = this.rentTotal + this.rentTotalVat;
    },
    recalculateOrderTotals() {
      this.orderTotal = Calculations.getItemSumByParamName(
        this.orderItemsForInvoice,
        "total_with_discount"
      );
      this.orderTotalVat = Calculations.getItemSumByParamName(
        this.orderItemsForInvoice,
        "vat"
      );
      this.orderTotalWithVat = this.orderTotal + this.orderTotalVat;
    },
    recalculateServiceTotals() {
      this.serviceTotal = Calculations.getItemSumByParamName(
        this.serviceItemsForInvoice,
        "total_with_discount"
      );
      this.serviceTotalVat = Calculations.getItemSumByParamName(
        this.serviceItemsForInvoice,
        "vat"
      );
      this.serviceTotalWithVat = this.serviceTotal + this.serviceTotalVat;
    },
    formatRentItems() {
      return this.rentItemsForInvoice.map((invoiceableItem) => {
        const item = {};
        item["id"] = invoiceableItem.id;
        item["end_date"] = invoiceableItem.end_date;
        item["discount"] = invoiceableItem.discount;
        item["vat_rate"] = invoiceableItem.vat_rate;
        item["quantity"] = invoiceableItem.quantity;

        if (invoiceableItem.readings.length > 0) {
          item["readings"] = invoiceableItem.readings;
        }

        return item;
      });
    },
    setRentItemErrors() {
      this.formValidation = {
        customer: {
          rules: ["required"],
        },
      };

      this.rentItemsForInvoice.forEach((invoiceableItem) => {
        if (invoiceableItem.by_quantity) {
          this.formValidation[`quantity-${invoiceableItem.id}`] = {
            rules: ["required"],
          };
        }

        invoiceableItem.readings.map((reading) => {
          if (!reading.is_last_reading) {
            this.formValidation[`newReading-${reading.id}`] = {
              rules: ["required"],
            };
          }
        });
      });
    },

    getRentItemsValidationValues() {
      this.setRentItemErrors();
      const form = this.form;

      this.rentItemsForInvoice.map((invoiceableItem) => {
        form[`newReading-${invoiceableItem.id}`] = invoiceableItem.price;
      });
      this.rentItemsForInvoice.forEach((invoiceableItem) => {
        if (invoiceableItem.by_quantity) {
          form[`quantity-${invoiceableItem.id}`] = invoiceableItem.quantity;
        }

        invoiceableItem.readings.map((reading) => {
          form[`newReading-${reading.id}`] = reading.newReading;
        });
      });

      return form;
    },

    formatOrderItems() {
      return this.orderItemsForInvoice.map((invoiceableItem) => {
        return {
          id: invoiceableItem.id,
          discount: invoiceableItem.discount,
          vat_rate: invoiceableItem.vat_rate,
          description: invoiceableItem.description,
        };
      });
    },

    formatServiceItems() {
      return this.serviceItemsForInvoice.map((invoiceableItem) => {
        return {
          id: invoiceableItem.id,
          discount: invoiceableItem.discount,
          vat_rate: invoiceableItem.vat_rate,
          description: invoiceableItem.description,
        };
      });
    },

    setData() {
      var data = {
        invoice_date: this.form.invoiceDate,
        due_date: this.customerData.payment_details.due_date,
        prepared_electronically: this.form.prepared_electronically,
        display_acts: this.form.display_acts,
        reverse_vat: this.form.reverse_vat,
        lang: this.form.lang,
        branch_id: this.form.customerBranch
          ? this.form.customerBranch.id
          : null,
        managing_branch_id: this.form.ownerBranch
          ? this.form.ownerBranch.id
          : null,
        notes: this.form.notes,
        advance: this.form.advance > 0 ? this.form.advance : 0,
      };

      if (this.rentItemsForInvoice.length > 0) {
        data.rent_invoiceable_items = this.formatRentItems();
      }

      if (this.orderItemsForInvoice.length > 0) {
        data.invoiceable_order_items = this.formatOrderItems();
      }

      if (this.serviceItemsForInvoice.length > 0) {
        data.invoiceable_service_items = this.formatServiceItems();
      }

      return data;
    },
    submit() {
      this.$Progress.start();
      const form = this.getRentItemsValidationValues();
      if (Validation(this.formValidation, form)) {
        this.$store.dispatch("createNewCustomerInvoice", {
          data: this.setData(),
          customerId: this.form.customer.id,
        });
      } else this.$Progress.fail();
    },
    destroyed() {
      this.$store.dispatch("clearInvoiceableCustomerData");
      this.$store.dispatch("clearSelectedCustomer");
    },
  },
};
</script>